jQuery(function($) {
  var dFlipLocation = "/wp-content/themes/trailsofindochina/assets/html/dflip/";

  function init() {
    window.addEventListener("scroll", function (e) {
      var distanceY =
          window.pageYOffset || document.documentElement.scrollTop;
      var shrinkOn = $("header").outerHeight() + 0;
      var header = document.querySelector("#header-menu");
      if (distanceY > shrinkOn) {
        classie.add(header, "smaller");
        $("#header-menu").css("top", 0);
      } else {
        if (classie.has(header, "smaller")) {
          classie.remove(header, "smaller");
          $("#header-menu").css("top", "-100%");
        }
      }
    });
  }


  function setMaxHeight() {
    /*  var $max_height = $(".blog-max-height");
      if ($max_height.length > 0) {
        $(".blog-max-height").matchHeight();
      }*/

    var $max_height = $(".max-height");
    if ($max_height.length > 0) {
      $(".max-height").matchHeight();
    }

    var $inspiration_itinerary_max_height = $(".inspiration-itinerary-max-height");
    if ($inspiration_itinerary_max_height.length > 0) {
      $(".inspiration-itinerary-max-height").matchHeight();
    }

    var $footer_height = $(".footer-height");
    if ($footer_height.length > 0) {
      if ($(window).width() >= 768) {
        $(".footer-height").matchHeight(false);
      } else {
        $(".footer-height").matchHeight();
      }
    }

    var $unchanged_block = $(".unchanged-block");
    if ($unchanged_block.length > 0) {
      $(".unchanged-block").matchHeight();
    }
  }

  function setHeaderMenuUnderline() {
    var path = window.location.pathname;
    if (path.indexOf("destination") != -1) {
      $(".menu-item-object-destination").addClass("hover");
    } else {
      $(".menu-item-object-destination").removeClass("hover");
    }

    if (path.indexOf("inspiration") != -1) {
      $(".menu-item-object-inspiration").addClass("hover");
    } else {
      $(".menu-item-object-inspiration").removeClass("hover");
    }
  }

  function addClassForNewsletterForm() {
    $('.subscribe-newsletter-content .ginput_container_email').addClass('input');
    $('.subscribe-newsletter-form-content .ginput_container_email').append('<div class="bg-content"></div><div class="bg-top-side"></div><div class="bg-bottom-side"></div><div class="bg-left-side"></div><div class="bg-right-side"></div>');
    $('.newsletter-form-content .ginput_container_email').addClass('input');
    $('.newsletter-form-content .ginput_container_email').append('<div class="bg-content"></div><div class="bg-top-side"></div><div class="bg-bottom-side"></div><div class="bg-left-side"></div><div class="bg-right-side"></div>');
  }

  function addClassForGetInTouchForm() {
    $('.register-interest-form-content .ginput_container_phone').addClass('input');
    $('.register-interest-form-content .ginput_container_phone').append('<div class="bg-content"></div><div class="bg-top-side"></div><div class="bg-bottom-side"></div><div class="bg-left-side"></div><div class="bg-right-side"></div>');
    $('.register-interest-form-content .ginput_container_email').addClass('input');
    $('.register-interest-form-content .ginput_container_email').append('<div class="bg-content"></div><div class="bg-top-side"></div><div class="bg-bottom-side"></div><div class="bg-left-side"></div><div class="bg-right-side"></div>');
    $('.register-interest-form-content .ginput_container_text').addClass('input');
    $('.register-interest-form-content .ginput_container_text').append('<div class="bg-content"></div><div class="bg-top-side"></div><div class="bg-bottom-side"></div><div class="bg-left-side"></div><div class="bg-right-side"></div>');
    $('.register-interest-form-content .ginput_container_textarea').addClass('input');
    $('.register-interest-form-content .ginput_container_textarea').append('<div class="bg-content"></div><div class="bg-top-side"></div><div class="bg-bottom-side"></div><div class="bg-left-side"></div><div class="bg-right-side"></div>');
  }

  function setUpGravityForm() {
    jQuery(document).bind('gform_confirmation_loaded', function (event, formId) {
      if (formId == 2) {
        $confirmation = document.getElementById("gform_confirmation_message_2").childNodes[0].textContent;
        document.getElementById("gform_confirmation_message_2").childNodes[0].remove();
        $("#newsletter-confirmation").text($confirmation);
        $.ajax({
          type: 'GET',
          url: admin_ajax_url, // this var is defined in footer.php
          data: { action: 'reloadNewsletterGravityFormAjax' }, // this is written in functions.php
          success: function (data) {
            $("#newsletter-gravity-form").html(data);
            addClassForNewsletterForm();
          }
        });
        $('#newsletter-form').modal('show');
      }
      if (formId == 4) {
        $confirmation = document.getElementById("gform_confirmation_message_4").childNodes[0].textContent;
        document.getElementById("gform_confirmation_message_4").childNodes[0].remove();
        $("#get-in-touch-confirmation").text($confirmation);
        $.ajax({
          type: 'GET',
          url: admin_ajax_url, // this var is defined in footer.php
          data: { action: 'reloadGetInTouchGravityFormAjax' }, // this is written in functions.php
          success: function (data) {
            $("#get-in-touch-gravity-form").html(data);
            addClassForGetInTouchForm();
          }
        });
        $('#get-in-touch-form').modal('show');
      }
    });

    jQuery(document).bind('gform_post_render', function (event, formId) {
      setTimeout(function () {
        if (formId == 2) {
          $email_input = $(".subscribe-newsletter-form-content #input_2_2");
          if ($email_input[0] && $email_input[0].value) {
            $email_input[0].value = "";
          }
          $email_input = $(".newsletter-form-content #input_2_2");
          if ($email_input[0] && $email_input[0].value) {
            $email_input[0].value = "";
          }
          addClassForNewsletterForm();
        }

        if (formId == 4) {
          $email_input = $(".register-interest-form-content #input_4_6");
          if ($email_input[0] && $email_input[0].value) {
            $(".register-interest-form-content #input_4_6")[0].value = "";
            $(".register-interest-form-content #input_4_1")[0].value = "";
            $(".register-interest-form-content #input_4_8")[0].value = "";
            $(".register-interest-form-content #input_4_4")[0].value = "";
          }
          addClassForGetInTouchForm();
        }
      }, 100);
    });
  }

  window.onload = init();
  $(document).ready(function () {
    setMaxHeight();
    setHeaderMenuUnderline();
    setUpGravityForm();

    $('#enquiry-form').on('shown.bs.modal', function () {
      $.fn.modal.Constructor.prototype.enforceFocus = function () { };
    });

    var tableList = $("table.table1");
    var maxWidth = 0;
    tableList.each(function (index) {
      var theWidth = $(this).find("tr > td:first").width();
      if (maxWidth < theWidth) {
        maxWidth = theWidth;
      }
    });
    //var theWidth = $("table.table1").eq(0).find("tr > td:first").width();
    //$("table.table1").find("tr > td:first").width(maxWidth + "px");

    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
      callback: function (box) {

      },
      scrollContainer: null
    });
    wow.init();

    $('.tes-block > .desc').on('click', function () {
      $('.tes-block > .desc').removeClass('is-focused')
      $(this).addClass('is-focused');
    });

    $("#header-menu .fix-menu-hidden-ori input").focus(function () {
      $("#header-menu .fix-menu-hidden-ori").addClass("focused");
    }).blur(function () {
      $("#header-menu .fix-menu-hidden-ori").removeClass("focused");
    })

    $("#header-menu-full .sub-menu-2 li").hover(function (e) {
      $("#header-menu-full .sub-menu-2 li").removeClass("active");
      $(this).addClass("active", e.type === "mouseenter");
      var myindex = $(this).index();
      $("#header-menu-full .block-to-show").removeClass("show-block");
      $("#header-menu-full .block-to-show")
          .eq(myindex)
          .addClass("show-block");
    });

    $("#header-menu .sub-menu-2 li").hover(function (e) {
      $("#header-menu .sub-menu-2 li").removeClass("active");
      $(this).addClass("active", e.type === "mouseenter");
      var myindex = $(this).index();
      $("#header-menu .block-to-show").removeClass("show-block");
      $("#header-menu .block-to-show")
          .eq(myindex)
          .addClass("show-block");
    });

    $(".list-inspiration li a").on("click", function () {
      $(".list-inspiration li a").removeClass("active");
      $(this).addClass("active");
    });

    $(".tab-ourdestinations a").on("click", function () {
      $(".tab-ourdestinations a").removeClass("active");
      $(this).addClass("active");
    });

    $("#header-menu-full .menu li").on("click", function () {
      $("#header-menu-full .menu li").removeClass("active");
      $(this).addClass("active");
    });

    $(".hg-contact-tablist a").on("click", function () {
      $(".hg-contact-tablist a").removeClass("active");
      $(this).addClass("active");
    });

    $(".owl-carousel-banner").on("initialized.owl.carousel", function () {
      $(".owl-item.active")
          .find("video")
          .each(function () {
            if ($("video").parent().parent().parent().hasClass("active")) {
              this.play();
            }
          });
    });
    var _countBanner = $(".owl-carousel-banner>div").length;
    $(".owl-carousel-banner").owlCarousel({
      stagePadding: 0,
      loop: _countBanner > 1,
      mouseDrag: _countBanner > 1,
      touchDrag: _countBanner > 1,
      pullDrag: _countBanner > 1,
      margin: 0,
      autoplay: true,
      nav: true,
      navText: ["", ""],
      transitionStyle: "fade",
      animateIn: "fadeIn",
      animateOut: "fadeOut",
      onTranslated: function () {
        $(".owl-item.active")
            .find("video")
            .each(function () {
              if ($("video").parent().parent().parent().hasClass("active")) {
                this.play();
              } else { }
            });
      },
      onTranslate: function () {
        $(".owl-item.active")
            .find("video")
            .each(function () {
              if (
                  $("video")
                      .parent()
                      .parent()
                      .parent()
                      .hasClass("active")
              ) {
                this.pause();
              } else { }
            });
      },

      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });

    $(".owl-slide-tem").owlCarousel({
      stagePadding: 0,
      loop: true,
      margin: 0,
      nav: true,
      navText: ["", ""],
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });

    var travelStyleCallback = function (event) {
      var targetName = event.targetName == undefined ? ".tab-pane.active" : event.targetName;
      var hItem = $("#section-travelstyle " + targetName + " .owl-item.active").outerHeight(true);
      var hBox = $("#section-travelstyle " + targetName).outerHeight(true);
      var hSection = $("#section-travelstyle").outerHeight(true);
      var alpha = hItem - hSection;
      if (alpha > 0) {
        var hText = $("#section-travelstyle .tab-pane.active .text2").height() + Math.abs(alpha) + 40;
        $("#section-travelstyle .tab-pane .text2").each(function (s, ss) {
          if ($(this).height() < hText) {
            $(this).height(hText);
          }
        });
      }
      hBox = $("#section-travelstyle " + targetName).outerHeight(true);
      if (hItem - hBox > -20)
        $("#section-travelstyle .tab-pane.active .trip-block-wrap").css("top", -(hItem - hBox) / 2);

    }

    $(window).on("load", function () {
      setTimeout(travelStyleCallback({}), 500);
      $(document).on('click', '#section-travelstyle a[data-toggle="tab"]', function (e) {
        setTimeout(function () {
          $("#section-travelstyle .trip-block").matchHeight();
          travelStyleCallback({});
        }, 500);
      });

      setMaxHeight();
    });

    $(".owl-slide-travel-style").owlCarousel({
      stagePadding: 0,
      loop: true,
      margin: 0,
      smartSpeed: 1000,
      nav: true,
      navText: ["", ""],
      dots: false,
      items: 1,
      animateIn: "fadeIn",
      animateOut: "fadeOut"
    });


    $(".owl-slide-experiences").each(function (i, e) {
      var isDrag = $(this).find(".item").length > 1;
      $(this).owlCarousel({
        stagePadding: 0,
        loop: isDrag,
        margin: 0,
        nav: true,
        navText: ["", ""],
        dots: false,
        items: 1,
        autoHeight: true,
        mouseDrag: isDrag,
        touchDrag: isDrag,
        onInitialized: function (event) {
          var hSlider = $(".owl-slide-experiences .owl-stage-outer").height();
          $(".owl-slide-experiences .owl-stage-outer").height(hSlider);
        }
      });
    });

    $(".owl-slide-init-trip").owlCarousel({
      stagePadding: 0,
      loop: true,
      margin: 0,
      nav: true,
      navText: ["", ""],
      dots: false,
      items: 1
    });

    $(".owl-slide-smallgroup-iti").owlCarousel({
      stagePadding: 0,
      loop: false,
      margin: 0,
      nav: true,
      navText: ["", ""],
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        }
      }
    });

    // $(".owl-slide-hotel").owlCarousel({
    //   stagePadding: 0,
    //   loop: false,
    //   margin: 0,
    //   nav: true,
    //   navText: ["", ""],
    //   dots: false,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     600: {
    //       items: 2
    //     },
    //     1000: {
    //       items: 3
    //     },
    //     1200: {
    //       items: 4
    //     }
    //   }
    // });

    $(".owl-carousel-mtt-team").owlCarousel({
      stagePadding: 0,
      loop: false,
      margin: 30,
      nav: true,
      navText: ["", ""],
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 2
        },
        600: {
          items: 3
        },
        1200: {
          items: 4
        }
      }
    });

    var _owl_mtt_team2 = $(".owl-slide-mtt-management-team2");

    function _load_owl_mtt_team2() {
      _owl_mtt_team2.owlCarousel({
        stagePadding: 0,
        loop: true,
        margin: 30,
        nav: true,
        navText: ["", ""],
        dots: false,
        items: 1
      });
    }
    var wW = $(window).width();
    if (wW >= 992) {
      _load_owl_mtt_team2();
    }
    $(window).resize(function () {
      var wW = $(window).width();
      if (wW <= 991) {
        _owl_mtt_team2.owlCarousel("destroy");
      } else {
        _load_owl_mtt_team2();
      }
    });
    // $(".des-menu-wrap a").each(function () {
    //   if ($($(this).attr("href")).length < 1) {
    //     console.log("Missing block: " + $(this).attr("href"));
    //     $(this)
    //       .parent()
    //       .remove();
    //   }
    // });

    if ($(".des-menu").length > 0) {
      var height_1 = $(".des-menu-wrap").height();
      $(".des-menu a").click(function (e) {
        e.preventDefault();
        var id = $(this).attr("href");
        var top = $(id).position().top;
        $("body,html").animate({
          scrollTop: top + 70
        });
      });
    }

    $(".video-poster .video-poster-play").click(function () {
      var poster = $(this)
          .parents(".video-poster")
          .find(".poster");
      var video = $(this)
          .parents(".video-poster")
          .find(".video");
      var iframe = $(this)
          .parents(".video-poster")
          .find("iframe");
      var link = iframe.attr("src");
      //iframe.attr("src", link + "&autoplay=1");
      poster.addClass("hideposter");
      video.addClass("active");
      $(this).parents(".video-poster").find(".huvi-border-bottom,.huvi-border-top").hide();
    });

    $("#inspiration-list a").click(function (e) {
      e.preventDefault();
      var img = $(this).data("img");
      var time = $(this).data("time");
      var des = $(this).data("des");
      $("#inspiration-img").attr("src", img);
      $("#inspiration-time").html(time);
      $("#inspiration-des").html(des);
      $("#inspiration-list a").removeClass("active");
      $(this).addClass("active");
      $("#inspiration-img").addClass("out");
      $("#inspiration-time").addClass("out");
      $("#inspiration-des").addClass("out");
      setTimeout(function () {
        $("#inspiration-img").removeClass("out");
        $("#inspiration-time").removeClass("out");
        $("#inspiration-des").removeClass("out");
      }, 200);
    });

    $(".hg-btn-modal").click(function (e) {
      var check_display = $(".check-display");
      var button_send_form = $("#send-form-enquiry");
      var tailor_button = $("#tailor-button");
      var smallgroup_button = $("#smallgroup-button");
      var expert_button = $("#expert-button");
      var tailor_made_form = $(".tailor-made-form");
      var small_group_form = $(".small-group-form");
      var expert_form = $(".expert-form");
      $(".hg-btn-modal").removeClass("active");
      $(this).addClass("active");
      if (!check_display.is(":visible")) {
        button_send_form.fadeIn();
      } else { }
      if (tailor_button.hasClass("active")) {
        if (!check_display.is(":visible")) {
          tailor_made_form.slideDown();
        } else {
          check_display.fadeOut(300);
          tailor_made_form.fadeIn(500);
        }
      } else if (smallgroup_button.hasClass("active")) {
        if (!check_display.is(":visible")) {
          small_group_form.slideDown();
        } else {
          check_display.fadeOut(300);
          small_group_form.fadeIn(500);
        }
      } else if (expert_button.hasClass("active")) {
        if (!check_display.is(":visible")) {
          expert_form.slideDown();
        } else {
          check_display.fadeOut(300);
          expert_form.fadeIn(500);
        }
      }
    });

    $(".block-past-tour")
        .slice(0, 1)
        .show();
    $("#view-more-past-tour").on("click", function (e) {
      e.preventDefault();
      $(".block-past-tour:hidden")
          .slice(0, 1)
          .slideDown();
      new WOW().init();
      if ($(".block-past-tour:hidden").length == 0) {
        $("#view-more-past-tour").fadeOut("slow");
      }
    });

    /* ////// Menu Nav Hover Dropdown ///////// */
    $("ul.menu li.dropdown").hover(
        function () {
          if ($(window).width() > 767) {
            $(this)
                .find(".dropdown-menu")
                .stop(true, true)
                .delay(100)
                .fadeIn(350);
          }
        },
        function () {
          if ($(window).width() > 767) {
            $(this)
                .find(".dropdown-menu")
                .stop(true, true)
                .delay(100)
                .fadeOut(350);
          }
        }
    );

    $(".dropdown").on("show.bs.dropdown", function (e) {
      if ($(window).width() < 768) {
        $(this)
            .find(".dropdown-menu")
            .first()
            .stop(true, true)
            .slideDown(300);
      }
    });

    $(".dropdown").on("hide.bs.dropdown", function (e) {
      if ($(window).width() < 768) {
        $(this)
            .find(".dropdown-menu")
            .first()
            .stop(true, true)
            .slideUp(200);
      }
    });

    function ori_fix_menu() {
      setTimeout(function () {
        if ($(window).width() <= 543) {
          $checkAll = $('#check1');
          if ($checkAll.length) {
            if ($checkAll.is(":checked")) {
              $('.header .form-search .input-search').css('top', -17);
            } else {
              $checkMore = $('#check9');
              if ($checkMore.is(":checked")) {
                $('.header .form-search .input-search').css('top', -17);
              } else {
                $('.header .form-search .input-search').css('top', -5);
              }
            }
          } else {
            $('.header .form-search .input-search').css('top', -17);
          }
        } else {
          $('.header .form-search .input-search').css('top', -17);
        }
      }, 100);

      if ($(window).width() >= 940 && $(window).width() <= 991) {

        $(".ori_add_style").html(
            "<style>#header-menu-full .header-menu-bg::before, #header-menu-full .header-menu-bg::after{width:" +
            Math.ceil(($(window).width() - 940) / 2) +
            "px;}</style>"
        );
      } else if ($(window).width() >= 1285 && $(window).width() <= 1499) {

        $(".ori_add_style").html(
            "<style>#header-menu-full .header-menu-bg::before, #header-menu-full .header-menu-bg::after{width:" +
            Math.ceil(($(window).width() - 1285) / 2 + 5) +
            "px;}</style>"
        );
      } else if ($(window).width() >= 1500) {
        $(".ori_add_style").html(
            "<style>#header-menu-full .header-menu-bg::before, #header-menu-full .header-menu-bg::after{width:" +
            Math.ceil(($(window).width() - 1500) / 2 + 5) +
            "px;}</style>"
        );
      } else {
        $(".ori_add_style").html("<style></style>");
      }

      if ($(window).width() >= 992) {
        $('#section-travelstyle .block-box-content5').css('min-height', $('#section-travelstyle .trip-block-wrap').height() - 115);
        $('#section-travelstyle .block-box-content6').css('min-height', $('#section-travelstyle .trip-block-wrap').height() - 115);
        $('#section-travelstyle .block-box-content2').css('min-height', $('#section-travelstyle .owl-stage-outer').height() - 160);
      } else {
        $('#section-travelstyle .block-box-content5').removeAttr("style");
        $('#section-travelstyle .block-box-content6').removeAttr("style");
        $('#section-travelstyle .block-box-content2').removeAttr("style");
      }

    }

    function fix_feature_itinerary() {
      $('.trip-post-large').css('min-height', $('.trip-post-large .content2').height() + 60);
    }

    ori_fix_menu();
    fix_feature_itinerary();
    set_height_top_contact();
    set_height_top_faq();
    set_height_gradient_top();
    set_height_top_search();
    window.addEventListener("resize", function (event) {
      setMaxHeight();
      ori_fix_menu();
      fix_feature_itinerary();
      set_height_top_contact();
      set_height_top_faq();
      set_height_gradient_top();
      set_height_top_search();
      setTimeout(function () {
        updateTECHeight();
      }, 1000);
    });

    function set_height_top_contact() {
      var top_contact = $("#top-bg-contact");
      var header_height =
          $(".header-menu-bg").height() + $(".header-top").outerHeight();
      top_contact.css({
        height: header_height
      });
    }

    function set_height_top_search() {
      var top_search = $("#top-bg-search");
      var header_height =
          $(".header-menu-bg").height() + $(".header-top").outerHeight();
      top_search.css({
        height: header_height
      });
    }

    function set_height_top_faq() {
      var top_faq = $("#top-bg-faq");
      var header_height =
          $(".header-menu-bg").height() + $(".header-top").outerHeight();
      top_faq.css({
        height: header_height
      });
    }

    function set_height_gradient_top() {
      var gradient_top = $(".gradient-top");
      var header_height =
          $(".header-menu-bg").height() + $(".header-top").outerHeight() - 30;
      gradient_top.css({
        height: header_height
      });
    }

    $(".type-contact input[type=radio]").change(function () {
      if ($(this).is(":checked") && $(this).val() == "emailme")
        $(".type-contact .tc-bonus").animate({
          opacity: 0
        }, 500);
      else $(".type-contact .tc-bonus").animate({
        opacity: 1
      }, 500);
    });
    /* July 4 2017 */

    /* ========================= */

    /*          INIT             */

    /* ========================= */

    $('.lazy').lazy({
      placeholder: "data:image/png;base64,R0lGODlhEALAPQAPzl5uLr9Nrl8e7...",
    });
    UI_RemoveGoogleMap();
    UI_Inpriration_Detail();
    UI_HotelPage();
    UI_AgentPage();
    UI_SectionTravelstyle();
    UI_Oriole();
    UI_Magazine();
    UI_AgentSite();
    UI_AgentSite_Ins_Detail();
    UI_AgentSite_Res_Video();
    UI_formfk();
    UI_Testimonial();
    UI_SearchPage();
    UI_ItiSliderTestimonial();
    showItinerary();
    closeMTTModal();
  });


  function UI_RemoveGoogleMap() {
    if ($("#map").length < 1) return;
    $("#script-googlemap").attr("src", $("#script-googlemap").attr("data-src"));
  }

  function UI_SectionTravelstyle() {
    if ($("#section-travelstyle").length < 1) {
      return;
    }
    $("#section-travelstyle .tab-list2 a").click(function () {
      var id = $(this).attr("href");
      $("#section-travelstyle .tab-pane").removeClass("active");
      $(id).addClass("active");
      $("#section-travelstyle .tab-list2 li").removeClass("active");
      $("#section-travelstyle .tab-list2 li a[href='" + id + "']")
          .parent()
          .addClass("active");
    });
  }

  function UI_Sub_Inpriration_Detail() {
    if ($(".vg-inspiration-list").length < 1) {
      return;
    }

    $(".des-block a, .og-close").click(function (e) {
      e.preventDefault();
      $vg_og_expander = $(this).parent().next();
      if ($(this).hasClass("og-close")) {
        $vg_og_expander = $(this).parent().parent();
      }
      $vg_des_block_inline = $vg_og_expander.parent();
      var vHash = $(this).attr("data-hash");
      var vdbi_h = $vg_des_block_inline.find(".des-block").outerHeight();
      var vdbi_2 =
          $vg_og_expander.find(".og-expander-inner").outerHeight() + 20;
      if (!$vg_des_block_inline.hasClass("og-expanded")) {
        $(".vg-des-block-inline")
            .css("height", "")
            .removeClass("og-expanded");
        $(".vg-og-expander").removeAttr("style");
        $vg_og_expander
            .css("transition", "height 500ms ease-in")
            .css("height", vdbi_2 + "px");
        $vg_des_block_inline
            .css("transition", "height 350ms ease-in")
            .css("height", vdbi_h + vdbi_2 + "px");
        $vg_des_block_inline.addClass("og-expanded");
        window.location.hash = vHash;
      } else {
        $vg_og_expander
            .css("transition", "height 350ms ease-out")
            .css("height", "0px");
        $vg_des_block_inline
            .css("transition", "height 500ms ease-out")
            .css("height", vdbi_h + "px");

        setTimeout(function () {
          $(".vg-des-block-inline")
              .css("height", "")
              .removeClass("og-expanded");
          $(".vg-og-expander").css("height", "");
          history.pushState("", document.title, window.location.pathname + window.location.search);
        }, 300);
      }
    });

    /* HASH SHARE URL */
    if (window.location.hash) {
      var hash = window.location.hash;
      $focus = $(".des-block a[data-hash='" + hash + "']");
      if ($focus.length > 0) {
        // console.log(".des-block a[data-hash='" + hashw + "']");
        $focus[0].click();
        $(window).on("load", function () {
          $("html,body").animate({
            scrollTop: $focus.offset().top - $("#header-menu").outerHeight(true) - 30
          }, 800);
        });
      }
    }
  }

  function UI_Inpriration_Detail() {
    UI_Sub_Inpriration_Detail();

    if ($(".vg-trip-block-filter").length < 1) {
      return;
    }

    $("#load-more-ins-dl").click(function (e) {
      e.preventDefault();
      var h = $(".load-more-ins-height").height();
      $(".load-more-ins-height").fadeIn();
      $("#load-more-ins-content")
          .css("transition", "height 750ms ease-out")
          .css("height", h);
      $(this).fadeOut();
      setTimeout(function () {
        $("#load-more-ins-content").removeAttr("style");
      }, 500);
    });

    /* init Isotope */
    var $isotope_ins_dl = $(".vg-trip-block-list").isotope({
      itemSelector: ".col-xs-12",
      layoutMode: "fitRows"
    });

    var $filterValue = "*";

    $(window).on("resize", function () {
      setTimeout(function () {
        $isotope_ins_dl.isotope({
          filter: $filterValue
        });
      }, 500);
    });

    setTimeout(function () {
      $isotope_ins_dl.isotope({
        filter: $filterValue
      });
    }, 500);

    $isotope_ins_dl.on('arrangeComplete', function (event, filteredItems) {
      var count = filteredItems.length;
      //if (count < 3 && $filterValue != “*”) {
      if (count < 4) {
        $("#inspiration_sample_looking_for").removeClass('hide-filter');
      } else {
        $("#inspiration_sample_looking_for").addClass("hide-filter");
      }
      var padding = 15
      var itemPerRow = Math.floor($(window).width() / ($("#inspiration_sample_looking_for").width() + 30))
      if (itemPerRow > 3) {
        itemPerRow = 3;
      }
      if (count > 3) {
        count = count - 1;
      }
      if (count % itemPerRow == 0) {
        var row = count / itemPerRow
        setTimeout(function () {
          console.log(row);
          $(".vg-trip-block-list").height(($("#inspiration_sample_looking_for").height() + 30) * row);
        }, 500);
      }
    });

    $(".vg-trip-block-filter.first select").on("change", function () {
      $filterValue = this.value;
      $isotope_ins_dl.isotope({
        filter: this.value
      });
    });

    $(".vg-des-block-inline").each(function (v, l) {
      $(this).attr("data-keep", $(this).height());
    });
    $("select.type2").on("change", function () {
      $(".vg-des-block-inline")
          .css({
            height: "",
            transition: ""
          })
          .removeClass("og-expanded");
      $(".vg-og-expander").css("height", "");
      var filterValue = this.value;
      $(".vg-des-block-inline" + filterValue).each(function (v, l) {
        $(this).css({
          position: "static",
          height: $(this).attr("data-keep"),
          opacity: 1
        });
      });
      $(".vg-des-block-inline:not(" + filterValue + ")").css({
        position: "absolute",
        height: 0,
        opacity: 0
      });
    });
  }

  function UI_HotelPage() {

    if ($(".hotel-page").length < 1) {
      return;
    }

    var owl_hotel_slide = $(".hotel-slide-suggest .owl-carousel");
    owl_hotel_slide.owlCarousel({
      items: 3,
      margin: 30,
      dots: false,
      nav: false,
      loop: false,
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 1
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        }
      }
    });
    $(".hotel-slide-suggest .next").click(function () {
      owl_hotel_slide.trigger("next.owl.carousel");
    });
    $(".hotel-slide-suggest .prev").click(function () {
      owl_hotel_slide.trigger("prev.owl.carousel", [300]);
    });


    /* Slider content .iti-hotel-slider */
    var _countHotelSlider = $(".iti-hotel-slider .owl-carousel>div").length;
    var iti_hotel_slider = $(".iti-hotel-slider .owl-carousel");
    iti_hotel_slider.hide().fadeIn();
    iti_hotel_slider.owlCarousel({
      items: 1,
      dots: false,
      nav: false,
      loop: _countHotelSlider > 1,
      autoplay: true,
      autoplayHoverPause: true,
      autoplaySpeed: 2000,
      mouseDrag: _countHotelSlider > 1,
      touchDrag: _countHotelSlider > 1
    });
    if (_countHotelSlider < 2) {
      $(".iti-hotel-slider .next, .iti-hotel-slider .prev").hide();
    }
    $(".iti-hotel-slider .next").click(function () {
      iti_hotel_slider.trigger("next.owl.carousel");
    });
    $(".iti-hotel-slider .prev").click(function () {
      iti_hotel_slider.trigger("prev.owl.carousel", [300]);
    });

    /* Big Slider Hotel */
    var hotel_big_slider = $(".hotel-big-slider .main-carousel");
    hotel_big_slider.flickity({
      cellSelector: ".owl-item-ht",
      pageDots: false,
      prevNextButtons: false,
      adaptiveHeight: true,
      imagesLoaded: true
    });

    $(".hotel-big-slider .next").click(function () {
      hotel_big_slider.flickity("next", true);
    });
    $(".hotel-big-slider .prev").click(function () {
      hotel_big_slider.flickity("previous", true);
    });
    var hotel_main_thumbs = $(".hotel-big-slider .main-thumbs");
    hotel_main_thumbs.flickity({
      asNavFor: ".main-carousel",
      contain: true,
      pageDots: false,
      prevNextButtons: false
    });
  }

  function UI_AgentPage() {
    if ($(".agent-page").length < 1) {
      return;
    }

    var agent_owl_big_one = $(".agent-owl-big-one .main-carousel");
    agent_owl_big_one.hide().fadeIn();
    agent_owl_big_one.flickity({
      cellSelector: ".item",
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true
    });
    $(".agent-owl-big-one .next").click(function () {
      agent_owl_big_one.flickity("next", true);
    });
    $(".agent-owl-big-one .prev").click(function () {
      agent_owl_big_one.flickity("previous", true);
    });

    var agent_big_many = $(".agent-big-many .main-carousel");
    agent_big_many.flickity({
      asNavFor: ".agent-owl-big-one .main-carousel",
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true
    });
    $(".agent-big-many .next").click(function () {
      agent_big_many.flickity("next", true);
    });
    $(".agent-big-many .prev").click(function () {
      agent_big_many.flickity("previous", true);
    });
  }

  function UI_Oriole() {
    if ($("#faq-page-section").length < 1) {
      return;
    }

    var isotopeFAQ = function () {
      var $container = $(".tab-pane.active .grid");
      $container.imagesLoaded(function () {
        $container.masonry({
          columnWidth: ".faq-grid-item",
          itemSelector: ".faq-grid-item"
        });
      });
    }
    isotopeFAQ();
    $("a[data-toggle=tab]").each(function () {
      $(this).on("shown.bs.tab", isotopeFAQ);
    });
  }

  function UI_Testimonial() {
    if ($("#vg-testimonial").length < 1) {
      return;
    }
    /* init Isotope */
    $(function () {
      var $isotope_ins_dl = $(".list-testimonial");
      $isotope_ins_dl.imagesLoaded(function () {
        $isotope_ins_dl.isotope({
          itemSelector: ".col-xs-12",
          layoutMode: "fitRows"
        });
      });
      $(".vg-trip-block-filter.first select").on("change", function () {
        $isotope_ins_dl.isotope({
          filter: this.value
        });
      });
    });
  }

  function UI_Magazine() {
    if ($("#magazine-search-btn").length < 1) {
      return;
    }
    $("#magazine-search-btn").click(function (e) {
      e.preventDefault();
      if (!$(this).hasClass("shown")) {
        $(this).addClass("shown");
        $("#header-menu-full").addClass("show-search");
      } else {
        $(this).removeClass("shown");
        $("#header-menu-full").removeClass("show-search");
      }
    });
  }

  function UI_formfk() {
    if ($(".gform_wrapper").length < 1) return;
    var reRender = function () {
      $(".gform_wrapper .big-btn label").each(function () {
        $(this).html("<span>" + $(this).text() + "</span>");
      });
    };
    reRender();
    $(".gform_footer .gform_button").click(function () {
      var reRender_setInterval = setInterval(reRender, 1000);
      setTimeout(function () {
        clearInterval(reRender_setInterval);
      }, 10000);
    });
  }

  function UI_SearchPage() {
    if ($(".vg-filter-content").length < 1) return;

    var reRenderRowSearch = function () {
      if ($(window).width() < 768) {
        $(".vg-filter-content aside .desc").height("auto");
        return;
      }
      $(".vg-filter-content aside").each(function () {
        var hImgSearch = $(this).find(".vg-filter-img").height();
        $(this).find(".desc").height(hImgSearch + 3 - $(this).find("h5").outerHeight(true) - $(this).find("h6").outerHeight(true))
            .dotdotdot({
              watch: "window"
            });
      });
    }
    reRenderRowSearch();
    var waitReziseSearch;
    $(window).on("resize", function () {
      clearTimeout(waitReziseSearch);
      waitReziseSearch = setTimeout(reRenderRowSearch, 100);
    })
  }

  function UI_ItiSliderTestimonial() {
    if ($("#iti-trip-testimonial").length < 1) return;
    var isDragTripTestimonial = $(".owl-slide-init-trip-testimonial .item").length > 1;
    $(".owl-slide-init-trip-testimonial").owlCarousel({
      stagePadding: 0,
      loop: isDragTripTestimonial,
      margin: 0,
      nav: true,
      navText: ["", ""],
      dots: false,
      items: 1,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
      mouseDrag: isDragTripTestimonial,
      touchDrag: isDragTripTestimonial,
      pullDrag: isDragTripTestimonial,
      autoHeight: true
    });
  }

  function UI_AgentSite() {
    if ($(".list-img-gallery").length < 1) return;

    var img_gallery = $(".list-img-gallery")
    img_gallery.owlCarousel({
      items: 4,
      margin: 30,
      dots: false,
      nav: true,
      loop: false,
      navText: ["", ""],
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 2
        },
        768: {
          items: 3
        },
        1024: {
          items: 4
        }
      }
    });
  }

  function UI_AgentSite_Ins_Detail() {
    if ($(".ag-ins-detail").length < 1) return;
    /* init Isotope */
    var $isotope_ins_dl = $(".home-trip-block-list").isotope({
      itemSelector: ".col-xs-12",
      layoutMode: "fitRows"
    });
    $(".vg-trip-block-filter.first select").on("change", function () {
      $isotope_ins_dl.isotope({
        filter: this.value
      });
    });
  }

  function UI_AgentSite_Res_Video() {
    if ($(".ag-list-video").length < 1) return;
    $(".ag-list-video .img").click(function () {
      var url = $(this).data("src");
      var agResVideo = $("#ag-res-video");
      if (url != undefined && url != '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
          agResVideo.html('<iframe src="https://www.youtube.com/embed/' + match[2] + '?autoplay=1&fs=1&rel=0" allowfullscreen></iframe>');
          $("#agent-video-popup").modal("show");
        }
      }
    });
    $("#agent-video-popup").on("hidden.bs.modal", function () {
      $("#ag-res-video").html("");
    });
  }

  function showItinerary() {
    if ($(".itinerary-detail .itinerary-days").length > 6) {
      $(".show-itinerary").show();
      $(".itinerary-detail .itinerary-days").slice(6).hide();
    }
    $(".show-itinerary img").on("click", function () {
      $(".itinerary-detail .itinerary-days").slice(6).show();
      $('html,body').animate({
        scrollTop: $(".itinerary-detail .itinerary-days").eq(6).offset().top - 100
      });
      $(".show-itinerary").hide();
    })
  }

  function closeMTTModal() {
    $(".mtt-modal .og-close2").on("click", function (e) {
      $('.mtt-modal.in').modal('hide');
    })
  }

  function updateTECHeight() {
    $vg_des_block_inline = $(".vg-des-block-inline.og-expanded");
    $vg_og_expander = $vg_des_block_inline.find(".vg-og-expander");
    var vHash = $(".des-block a").attr("data-hash");
    var vdbi_h = $vg_des_block_inline.find(".des-block").outerHeight();
    //var vdbi_2 = $vg_og_expander.find(".og-expander-inner").outerHeight() + 20;
    var vdbi_2 = $vg_og_expander.find(".og-expander-inner").outerHeight();
    if ($vg_des_block_inline.hasClass("og-expanded")) {
      $vg_og_expander
          .css("transition", "height 500ms ease-in")
          .css("height", vdbi_2 + "px");
      $vg_des_block_inline
          .css("transition", "height 350ms ease-in")
          .css("height", vdbi_h + vdbi_2 + "px");
      window.location.hash = vHash;
    }
  }

  /**/
  $(document).keyup(function (e) {
    if (e.keyCode == 27) {
      $('.beauty-close').trigger('click');
    }
  });
});

